import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import imgHero from "../assets/image/conference/png/hero-main.png";
import imgC1 from "../assets/image/conference/jpg/content-1-img.jpg";
import imgCR from "../assets/image/conference/png/content-1-round.png";
import Content4 from "../sections/landing6/Content3";

const Hero = () => {
  return (
    <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 mt-28 position-relative">
        <div
          className="hero-area bg-image bg-blue  dark-mode-texts mx-6 mx-lg-9 rounded-15"
          css={`
            background-image: url(https://shadepro-gatsby.netlify.app/static/pattern-4-8263160699f4d9a09419f6536a57e66a.png);
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col lg="6" md="8">
                <div className="hero-content pe-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h1 className="title gr-text-2 mb-8 heading-color">Infrastructure Automation</h1>
                  <p className="gr-text-8 mb-0 text-color-opacity">
                  Cloud Infrastructure Automation Solutions, we specialize in empowering businesses to optimize
and secure their operations through advanced cloud automation technologies.
                  </p>
                  <Button href="/contact" variant="success" className=" mt-11 btn btn-success">
                    Contact us
                  </Button>
                </div>
              </Col>
              <Col
                lg="5"
                md="4"
                sm="7"
                className="offset-lg-1 align-self-sm-end position-relative">
                <div
                  className="hero-img hero-img-custom z-index-1 mb-n12 mb-md-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="500"
              >
                </div>
              </Col>
            </Row>
          </Container>
        </div>
          </div>
        <div className="content-section pt-13 pt-lg-29 pb-12 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-center ">
            <Col className="ps-lg-15">
              <div className="section-title content-text mb-13 mb-lg-15">
                <h2 className="title gr-text-4 mb-6 heading-color">Our Core Offerings</h2>
                <p className="gr-text-8 pe-lg-12 mb-0 text-color-opacity">
                Harnessing the power of automation, we enable organizations to streamline processes,
enhance scalability, and strengthen security across their cloud infrastructure.
                </p>
              </div>
              <div className="content-widget">             
                <Row className="mb-n11">
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        1. Automated Deployment and Provisioning
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Accelerate your time-to-market and reduce human error by automating the deployment
                      and provisioning of cloud resources. Our solutions leverage Infrastructure as Code (IaC)
                      principles to achieve consistent and repeatable infrastructure deployments.                      </p>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        2. Configuration Management
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Ensure consistency and enforce best practices across your cloud environment with
                      automated configuration management. We help you manage configurations, enforce
                      security policies, and track changes efficiently.{" "}
                      </p>
                    </div>
                </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        3. Auto-Scaling and Resource Optimization
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Optimize resource utilization and cost efficiency by automating auto-scaling based on
                      demand fluctuations. Our solutions dynamically adjust cloud resources to match
                      workload requirements, ensuring optimal performance and cost savings.
                      </p>
                    </div>
                  </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        4. Continuous Monitoring and Alerting
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Gain real-time visibility into your cloud infrastructure's health and performance. We
                      implement automated monitoring and alerting solutions to proactively identify and
                      respond to potential issues before they impact your operations.
                      </p>
                    </div>
                  </Col>
                 <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        5. Security Automation
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Enhance your cloud security posture with automated security controls and compliance
                      checks. From vulnerability scanning to automated incident response, we help you fortify
                      your cloud infrastructure against evolving threats.
                      </p>
                    </div>
                  </Col>               
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
    <Content4 />
    </PageWrapper>
  );
};

export default Hero;